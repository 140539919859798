import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`Update (5/2/2020): Added another bonus section about how to implement dark mode with Tailwind.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`Update (12/17/2020): Added a note about the `}<inlineCode parentName="p">{`purge`}</inlineCode>{` config in `}<inlineCode parentName="p">{`tailwing.config.js`}</inlineCode></p>
    </blockquote>
    <p>{`When creating the site for `}<a parentName="p" {...{
        "href": "https://getfluency.io"
      }}>{`Fluency`}</a>{`, I figured now was as good a time as any to try out `}<a parentName="p" {...{
        "href": "https://tailwindcss.com/"
      }}>{`Tailwind CSS`}</a>{`. Like all static sites I build nowadays, the site runs on `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/"
      }}>{`Gatsby`}</a>{`, but unfortunately the `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.org/docs/tailwind-css/"
      }}>{`docs`}</a>{` on how to integrate Tailwind with Gatsby are quite complicated. As someone who isn't super familiar with the current state of web build systems, PostCSS, etc, it took me longer than I thought to figure out how to integrate it.`}</p>
    <p>{`So, this is what I know as the minimal number of steps to start using Tailwind classes to style your CSS in a Gatsby site:`}</p>
    <ol>
      <li parentName="ol">{`Install packages:`}<pre parentName="li" {...{
          "className": "dark-default-dark vscode-highlight",
          "data-language": ""
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`npm install tailwindcss --save-dev`}</span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}>{`npm install --save gatsby-plugin-postcss`}</span></code></pre></li>
      <li parentName="ol">{`Add plugin to your `}<inlineCode parentName="li">{`gatsby-config.js`}</inlineCode>{`, so that Gatsby knows to load postcss (which loads Tailwind):`}<pre parentName="li" {...{
          "className": "dark-default-dark vscode-highlight",
          "data-language": "js"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`plugins: [`}</span><span parentName="span" {...{
                "className": "mtk8"
              }}>{`\`gatsby-plugin-postcss\``}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`],`}</span></span></code></pre></li>
      <li parentName="ol">{`Add the following to a new file at the root of the repo called `}<inlineCode parentName="li">{`postcss.config.js`}</inlineCode>{` so that postcss knows to load Tailwind:`}<pre parentName="li" {...{
          "className": "dark-default-dark vscode-highlight",
          "data-language": "js"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk10"
              }}>{`module`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mtk10"
              }}>{`exports`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` = () `}</span><span parentName="span" {...{
                "className": "mtk4"
              }}>{`=>`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` ({`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`  `}</span><span parentName="span" {...{
                "className": "mtk12"
              }}>{`plugins:`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` [`}</span><span parentName="span" {...{
                "className": "mtk11"
              }}>{`require`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mtk8"
              }}>{`"tailwindcss"`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`)],`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk1"
              }}>{`})`}</span></span></code></pre></li>
      <li parentName="ol">{`Create a new css file with the following content (the directives here apply Tailwind styles) - I called mine `}<inlineCode parentName="li">{`global.css`}</inlineCode>{`:`}<pre parentName="li" {...{
          "className": "dark-default-dark vscode-highlight",
          "data-language": "css"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk15"
              }}>{`@tailwind`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` base;`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk15"
              }}>{`@tailwind`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` components;`}</span></span>{`
`}<span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk15"
              }}>{`@tailwind`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` utilities;`}</span></span></code></pre></li>
      <li parentName="ol">{`Add the following to `}<inlineCode parentName="li">{`gatsby-browser.js`}</inlineCode>{`, so that Gatsby imports Tailwind on every page:`}<pre parentName="li" {...{
          "className": "dark-default-dark vscode-highlight",
          "data-language": "js"
        }}><code parentName="pre" {...{
            "className": "vscode-highlight-code"
          }}><span parentName="code" {...{
              "className": "vscode-highlight-line"
            }}><span parentName="span" {...{
                "className": "mtk15"
              }}>{`import`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mtk8"
              }}>{`"./src/global.css"`}</span><span parentName="span" {...{
                "className": "mtk1"
              }}>{`;`}</span></span></code></pre></li>
    </ol>
    <p>{`Voila, you should now be able to use Tailwind classes anywhere to style your site.`}</p>
    <h2 {...{
      "id": "bonus-vscode-autocompletion"
    }}>{`Bonus: VSCode Autocompletion`}</h2>
    <p>{`If you happen to use the Tailwind CSS Itellisense plugin for VS Code, which will autocomplete Tailwind CSS classes, there's an `}<a parentName="p" {...{
        "href": "https://github.com/bradlc/vscode-tailwindcss/issues/74"
      }}>{`issue`}</a>{` open indicating that a Tailwind config is required. In order to make this work, you'll need to run`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`npx tailwind init`}</span></code></pre>
    <h2 {...{
      "id": "bonus-dark-mode"
    }}>{`Bonus: Dark Mode`}</h2>
    <p>{`I loved `}<a parentName="p" {...{
        "href": "https://stefanzweifel.io/posts/support-mojave-dark-mode-with-tailwind-css/"
      }}>{`this article from Stefan Zweifel`}</a>{` about how to support dark mode in Tailwind: if you've already got a config file from the last step (`}<inlineCode parentName="p">{`tailwind init`}</inlineCode>{`), then you can copy this config to support dark mode out of the box:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "js"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// tailwind.config.js`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk10"
          }}>{`module`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`exports`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`purge:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'./src/**/*.jsx'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  ],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`theme:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`extend:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {},`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`screens:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'sm'`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'640px'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'md'`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'768px'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'lg'`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'1024px'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'xl'`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'1280px'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'dark-mode'`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'raw'`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`'(prefers-color-scheme: dark)'`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`},`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    },`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  },`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`variants:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {},`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`plugins:`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [],`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Then you can use `}<inlineCode parentName="p">{`dark-mode`}</inlineCode>{` as a specifier for your Tailwind styles: `}<inlineCode parentName="p">{`dark-mode:text-white`}</inlineCode>{` for example.`}</p>
    <p>{`(Note: I've included the built-in screens for Tailwind here, since if you just put in the `}<inlineCode parentName="p">{`dark-mode`}</inlineCode>{` specifier, other screens like `}<inlineCode parentName="p">{`md`}</inlineCode>{` won't work. I'm not sure if there's a way to extend the default configuration rather than redefining it.)`}</p>
    <p>{`(Also note: you'll need to include the `}<inlineCode parentName="p">{`purge`}</inlineCode>{` configuration so your generated files aren't huge in accordance with the `}<a parentName="p" {...{
        "href": "https://tailwindcss.com/docs/optimizing-for-production"
      }}>{`Tailwind recommendation`}</a>{`.)`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      